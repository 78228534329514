// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/Blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-farmers-js": () => import("./../../../src/pages/Farmers.js" /* webpackChunkName: "component---src-pages-farmers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-journey-js": () => import("./../../../src/pages/Journey.js" /* webpackChunkName: "component---src-pages-journey-js" */),
  "component---src-pages-our-journey-js": () => import("./../../../src/pages/OurJourney.js" /* webpackChunkName: "component---src-pages-our-journey-js" */),
  "component---src-pages-progressive-farming-js": () => import("./../../../src/pages/ProgressiveFarming.js" /* webpackChunkName: "component---src-pages-progressive-farming-js" */),
  "component---src-pages-trace-js": () => import("./../../../src/pages/trace.js" /* webpackChunkName: "component---src-pages-trace-js" */),
  "component---src-pages-traceable-js": () => import("./../../../src/pages/Traceable.js" /* webpackChunkName: "component---src-pages-traceable-js" */),
  "component---src-pages-zero-waste-js": () => import("./../../../src/pages/ZeroWaste.js" /* webpackChunkName: "component---src-pages-zero-waste-js" */)
}

